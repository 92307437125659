import { Grid, Typography } from "@mui/material";
import AppButton from "components/common/appButton";
import AppTextField from "components/common/appTextField";
import FormHeader from "components/common/formHeader";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { validator } from "utils/validator";
import { toast } from "react-toastify";
import { OrderResponseType } from "types/order/orderResponseType";
import { useBulkUpdateOrderItemsMutation } from "store/services/orderItem.service";
import { useCreateReceiptMutation } from "store/services/payment.service";
import { useCreateCdekOrderMutation } from "store/services/cdek.service";
import { CadesPluginer } from "@crpt/cades-pluginer";
import {
    useCheckCodesMutation,
    useLazyRequestAuthQuery,
    useSignInMutation
} from "store/services/chZnak.service";
import { SignInRequestType } from "types/chZnak/signIn";
import AppCheckBoxField from "components/common/appCheckboxField";

interface IData {
    [key: string]: string;
}

interface IOrderCheckOutForm {
    onFinish: () => void;
    order: OrderResponseType;
    title?: string;
}

const OrderCheckOutForm: React.FC<IOrderCheckOutForm> = ({
    order,
    onFinish,
    title
}) => {
    const [data, setData] = useState<IData>(
        order.orderItems.reduce((acc, curr) => {
            if (curr.productEntity.colorEntity.product.marking) {
                return {
                    ...acc,
                    [curr.id.toString()]: curr.dataMatrix || ""
                };
            } else {
                return acc;
            }
        }, {})
    );

    const [errors, setErrors] = useState<Partial<IData>>({});
    const isValid = Object.keys(errors).length === 0;
    const [isTrySubmit, setIsTrySubmit] = useState<boolean>(false);
    const [bulkUpdateOrderItems] = useBulkUpdateOrderItemsMutation();
    const [createReceipt] = useCreateReceiptMutation();
    const [createCdekOrder] = useCreateCdekOrderMutation();
    const [checkCodes] = useCheckCodesMutation();
    const [requestAuthData] = useLazyRequestAuthQuery();
    const [chZnakSignIn] = useSignInMutation();
    const [isCheckingCodes, setIsCheckingCodes] = useState<boolean>(false);
    const chZnakCheckTimeout = useRef<NodeJS.Timeout | null>(null);
    const [isChZnakCheck, setIsChZnakCheck] = useState<boolean>(true);

    useEffect(() => {
        const isEmptyCodes = Object.values(data).some(
            (value) => value.trim() === ""
        );

        if (!isEmptyCodes) {
            setErrors({});

            if (isChZnakCheck) {
                chZnakCheckTimeout.current &&
                    clearTimeout(chZnakCheckTimeout.current);
                const codes = Object.values(data).map((value) => value.trim());

                chZnakCheckTimeout.current = setTimeout(() => {
                    try {
                        setIsCheckingCodes(true);
                        handleCheckCodes(codes).then((checkCodesResult) => {
                            if (!checkCodesResult) return;

                            const codesErrors: Record<string, string> = {};

                            if (checkCodesResult.result === false) {
                                if (checkCodesResult.quantity) {
                                    Object.keys(data).forEach((key) => {
                                        codesErrors[key] =
                                            "Код маркировки неккоректный";
                                    });
                                    setErrors(codesErrors);
                                }
                                if (checkCodesResult.codes) {
                                    for (const code of checkCodesResult.codes) {
                                        const key = Object.keys(data).find(
                                            (key) => data[key] === code
                                        );
                                        if (key) {
                                            codesErrors[key] =
                                                "Код маркировки неккоректный";
                                        }
                                    }
                                    setErrors(codesErrors);
                                }
                            }
                            if (checkCodesResult.result === true) {
                                setErrors(codesErrors);
                            }
                            setIsCheckingCodes(false);
                        });
                    } catch (e: any) {
                        toast.error(e.message);
                        setIsCheckingCodes(false);
                    }
                }, 300);
            }
        }
    }, [data, isChZnakCheck]);

    const handleCheckCodes = useCallback(async (codes: Array<string>) => {
        let codesCheckResult;
        try {
            codesCheckResult = await checkCodes({
                codes: codes
            }).unwrap();
        } catch (e: any) {
            try {
                const authData = await requestAuthData().unwrap();

                const certsArray = await CadesPluginer.getFinalCertsArray();
                const signedDataString = await CadesPluginer.signMessage(
                    authData.data,
                    certsArray[0].certificate
                );

                const signedAuthData: SignInRequestType = {
                    uuid: authData.uuid,
                    data: signedDataString
                };
                await chZnakSignIn(signedAuthData).unwrap();
                codesCheckResult = await checkCodes({
                    codes: codes
                }).unwrap();
            } catch (e: any) {
                toast.error(
                    "Не удалось авторизоваться в системе Честный Знак. Подключите носитель с цифровой подписью"
                );
            }
        }

        return codesCheckResult;
    }, []);

    const handleSetChZnakCheck = () => {
        setIsChZnakCheck((prevState) => !prevState);
    };

    useEffect(() => {
        if (isTrySubmit) {
            validate();
        }
    }, [data]);

    const validatorConfig = Object.keys(data).reduce((acc, curr) => {
        return {
            ...acc,
            [curr.toString()]: {
                isRequired: {
                    message: "Введите или отсканируйте код маркировки"
                }
            }
        };
    }, {});

    const validate = () => {
        const errors = validator(data, validatorConfig);
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (target: { name: string; value: string }) => {
        setData((prevState) => ({
            ...prevState,
            [target.name]: target.value.replace(" ", "\u001d")
        }));
    };

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsTrySubmit(true);
        const isValid = validate();
        if (!isValid) return;
        try {
            if (Object.keys(data).length) {
                const updatedOrderItems = order.orderItems.map((oi) => ({
                    id: oi.id,
                    orderId: oi.orderId,
                    productEntityId: oi.productEntityId,
                    dataMatrix: data[oi.id.toString()]
                }));
                await bulkUpdateOrderItems(updatedOrderItems).unwrap();
            }
            await createReceipt(order.id).unwrap();
            await createCdekOrder(order.id).unwrap();

            toast.success(
                "Чек к заказy №" +
                    order.orderNumber +
                    " сформирован. Заказ на доставку создан"
            );

            onFinish();
        } catch (e: any) {
            toast.error(e.message);
        }
    };

    const [active, setActive] = useState(document.activeElement);

    useEffect(() => {
        const handleFocusIn = () => {
            setActive(document.activeElement);
        };

        document.addEventListener("focusin", handleFocusIn);
        return () => {
            document.removeEventListener("focusin", handleFocusIn);
        };
    }, []);

    const getFullProductEntityName = (orderItemId: string) => {
        let fullName = "";
        const orderItem = order.orderItems.find(
            (oi) => oi.id.toString() === orderItemId
        );
        if (orderItem) {
            const { productEntity } = orderItem;
            const size = productEntity.size.value;
            const color = productEntity.colorEntity.color.title;
            const name = productEntity.colorEntity.product.title;
            const collection =
                productEntity.colorEntity.product.collection.title;
            fullName = name + " " + collection + " " + size + " " + color;
        }
        return fullName;
    };

    return (
        <>
            {title && <FormHeader title={title} />}
            <form onSubmit={handleSubmit}>
                {Object.keys(data).map((orderItemId) => (
                    <Grid key={orderItemId} sx={{ mb: 4 }}>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                textTransform: "uppercase",
                                mb: 1
                            }}
                        >
                            {getFullProductEntityName(orderItemId)}
                        </Typography>
                        <AppTextField
                            name={orderItemId}
                            onChange={handleChange}
                            value={data[orderItemId]}
                            placeholder="Введите или отсканируйте код маркировки"
                            error={errors[orderItemId]}
                        />
                    </Grid>
                ))}
                <Grid container mt={1} justifyContent="end" alignItems="center">
                    <Typography sx={{ color: "red" }}>
                        Проверять коды Честный Знак?
                    </Typography>

                    <AppCheckBoxField
                        name="ChZnakCheck"
                        onChange={handleSetChZnakCheck}
                        checked={isChZnakCheck}
                    />
                </Grid>
                <Grid container justifyContent="space-between" mt={1}>
                    <AppButton onClick={onFinish} variant="contained">
                        Отмена
                    </AppButton>
                    <AppButton
                        type="submit"
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={!isValid || isCheckingCodes}
                    >
                        Выписать чек
                    </AppButton>
                </Grid>
            </form>
        </>
    );
};

export default OrderCheckOutForm;
