import { createApi } from "@reduxjs/toolkit/query/react";
import { config } from "config";
import { appBaseQueryWithReauth } from "./http.service";
import { AuthResponseType } from "types/chZnak/auth";
import { SignInRequestType } from "types/chZnak/signIn";
import {
    CheckCodesRequestType,
    CheckCodesResponseType
} from "types/chZnak/codes";

const chZnakEndpoint = config.apiEndpoint + "/chZnak";

export const chZnakApi = createApi({
    reducerPath: "chZnakApi",
    baseQuery: appBaseQueryWithReauth({ baseUrl: chZnakEndpoint }),
    endpoints: (builder) => ({
        requestAuth: builder.query<AuthResponseType, void>({
            query: () => ({
                url: "/authRequest",
                method: "GET"
            })
        }),
        signIn: builder.mutation<string, SignInRequestType>({
            query: (signedData) => ({
                url: "/signIn",
                data: signedData,
                method: "POST"
            })
        }),
        checkCodes: builder.mutation<
            CheckCodesResponseType,
            CheckCodesRequestType
        >({
            query: (codesData) => ({
                url: "/codesCheck",
                data: codesData,
                method: "POST"
            })
        })
    })
});

export const {
    useLazyRequestAuthQuery,
    useSignInMutation,
    useCheckCodesMutation
} = chZnakApi;
